export const NFT = `

// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

import "@openzeppelin/contracts/token/ERC721/ERC721.sol";
import "@openzeppelin/contracts/access/Ownable.sol";


contract MyNFT is ERC721, Ownable {
    uint256 public nextTokenId;
    address public admin;

    constructor() ERC721("MyNFT", "MNFT") {
        admin = msg.sender;
    }

    function mint(address to) external onlyOwner {
        _safeMint(to, nextTokenId);
        nextTokenId++;
    }
}
`;

export const DEFI = `

const { ethers } = require('ethers');

// Connect to Ethereum mainnet or testnet (e.g., via Infura)
const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID");

// Define wallet private key (Use .env or other secure storage in real applications)
const privateKey = "YOUR_PRIVATE_KEY";
const wallet = new ethers.Wallet(privateKey, provider);

// Uniswap V2 Router Contract Address (Ethereum Mainnet)
const uniswapRouterAddress = "0x7a250d5630b4cf539739dsdf2c5dAcb4c659F2488D";

// Uniswap V2 Router ABI (simplified for swapping functions)
const uniswapRouterABI = [
    "function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)",
    "function swapExactTokensForTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)"
];
`;

export const WalletDevelopment = `

const { ethers } = require('ethers');

// Connect to the Ethereum network (e.g., using Infura)
const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID");

// Generate a new wallet
async function createWallet() {
    const wallet = ethers.Wallet.createRandom();
    const privateKey = wallet.privateKey;
    const address = wallet.address;

    console.log(\`New Ethereum Wallet Address: \${address}\`);
    console.log(\`Private Key (Keep it secret!): \${privateKey}\`);

    // Check the balance of the newly created wallet
    await checkBalance(address);
}
`;

export const UserOps = `

// Approve DAI spending by the contract
async function approveDAISpending() {
    try {
        // Call the 'approve' function of the ERC-20 contract
        const tx = await daiContract.approve(spenderAddress, amountToApprove);
        console.log('Approval transaction sent:', tx.hash);


        // Wait for the transaction to be mined
        const receipt = await tx.wait();
        console.log('Approval transaction confirmed in block:', receipt.blockNumber);
    } catch (error) {
        console.error('Error approving tokens:', error);
    }
}
`;

export const DApps = `

// Send ETH to another address
async function sendETH() {
    const recipientAddress = document.getElementById('recipientAddress').value;
    const amount = document.getElementById('sendAmount').value;

    if (!recipientAddress || !amount) {

    
        alert('Please enter a recipient address and amount.');
        return;
    }

    try {
        const tx = await signer.sendTransaction({
            to: recipientAddress,
            value: ethers.utils.parseEther(amount)
        });

        console.log('Transaction sent:', tx.hash);
        alert(\`Transaction sent! Hash: \${tx.hash}\`);
    } catch (error) {
        console.error('Error sending ETH:', error);
    }
}
`;

export const Solana = `

const solanaWeb3 = require('@solana/web3.js');
 
// Create a connection to the Solana Devnet
const connection = new solanaWeb3.Connection(
    solanaWeb3.clusterApiUrl('devnet'),
    'confirmed'
);
 
// Generate a new keypair (private and public key)
const keypair = solanaWeb3.Keypair.generate();
 
console.log("Generated Keypair:");
console.log("Public Key:", keypair.publicKey.toBase58());
console.log("Secret Key:", keypair.secretKey);
 
// Get the balance of the account (0 SOL initially)
async function getBalance() {
    const publicKey = keypair.publicKey;
    const balance = await connection.getBalance(publicKey);
    console.log("Balance:", balance / solanaWeb3.LAMPORTS_PER_SOL, "SOL");
}
 
// Call the function to check balance
getBalance();
`;

export const ERC4337 = `

const { ethers } = require('ethers');
const { SimpleAccountAPI, getSimpleAccountOwner } = require('@account-abstraction/sdk');
const provider = new ethers.providers.JsonRpcProvider('https://rpc-url');  // Replace with your network RPC URL
 
async function main() {
    // Step 1: Setup Wallet or get Account owner
    
    const wallet = ethers.Wallet.createRandom();  // Or use your own wallet
    const signer = wallet.connect(provider);
    console.log("Signer Address:", signer.address);
 
    // Step 2: Deploy Account Abstraction Wallet (SimpleAccountAPI)
    const entryPointAddress = "0xEntryPointAddress";  // Replace with the correct entry point address
    const simpleAccountAPI = new SimpleAccountAPI({
        provider,
        entryPointAddress,
        owner: signer,  // Account owner (EOA)
    });
 
    // Step 3: Get or Deploy Wallet Address
    const walletAddress = await simpleAccountAPI.getCounterFactualAddress();
    console.log("Deployed Wallet Address:", walletAddress);
 
    // Step 4: Fund Wallet (optional, may need gas for deployment)
    const tx = {
        to: walletAddress,
        value: ethers.utils.parseEther("0.1")  // Send 0.1 ETH to the wallet
    };
    await signer.sendTransaction(tx);
 
    // Step 5: Create and Send a UserOperation
    const recipient = "0xRecipientAddress";  // Replace with the recipient address
    const valueToSend = ethers.utils.parseEther("0.01");  // Sending 0.01 ETH
 
    const userOp = await simpleAccountAPI.createSignedUserOp({
        target: recipient,
        value: valueToSend.toString(),
        data: "0x",  // Optional: data payload for a smart contract interaction
    });
 
    console.log("UserOperation:", userOp);
 
    // Step 6: Send UserOperation to the bundler/EntryPoint
    const entryPoint = simpleAccountAPI.entryPoint;
    const txResponse = await entryPoint.handleOps([userOp], signer.address);
    console.log("Transaction Hash:", txResponse.hash);
}
 
main().catch(console.error);
`;

export const ERC6551 = `

const { ethers } = require('ethers');

const implementationAddress = "0xImplementationAddress"; // Replace with actual implementation address
const registryAddress = "0xRegistryAddress"; // Replace with the registry address
const registryAbi = [...] // Replace with the ABI for the registry contract

 async function createTokenBoundAccount(tokenContract, tokenId) {
    const signer = ethers.provider.getSigner(); // Ensure signer is correctly initialized
    const registryContract = new ethers.Contract(registryAddress, registryAbi, signer);

    // Define the chain ID (e.g., 1 for Ethereum mainnet, 137 for Polygon)
    const chainId = 1; // Ethereum Mainnet

    // Optional salt value for counterfactual deployments
    const salt = 0;

    // Call the registry to create a Token Bound Account for the given token (NFT)
    const tx = await registryContract.createAccount(
        implementationAddress,
        chainId,
        tokenContract,
        tokenId,
        salt
    );

    // Wait for transaction to be confirmed
    const receipt = await tx.wait();

    // Extract the token bound account address from the receipt event logs
    const tokenBoundAccountAddress = receipt.events[0].args[0];
    
    console.log(\`Token Bound Account Address: \${tokenBoundAccountAddress}\`);
    return tokenBoundAccountAddress;
}
`;

export const ERC7702 = `

const { ethers } = require('ethers');
 
// Connect to an Ethereum provider (e.g., Infura, Alchemy, or local node)
const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID');
 

 const wallet = ethers.Wallet.createRandom(); // Generate a new EOA (private and public key pair)
 
console.log("Wallet Address (EOA):", wallet.address);
console.log("Private Key:", wallet.privateKey);  // Keep this private and secure!
 
// Connect wallet to the provider
const walletWithProvider = wallet.connect(provider);
 
// Check the balance of the wallet
async function checkBalance() {
    const balance = await walletWithProvider.getBalance();
    console.log("Balance:", ethers.utils.formatEther(balance), "ETH");
}
 
// Send a transaction from the EOA
async function sendTransaction() {
    const tx = {
        to: "0xRecipientAddress",  // Replace with the recipient's address
        value: ethers.utils.parseEther("0.01")  // Send 0.01 ETH
    };
 
    const transactionResponse = await walletWithProvider.sendTransaction(tx);
    console.log("Transaction Hash:", transactionResponse.hash);
 
    // Wait for the transaction to be confirmed
    await transactionResponse.wait();
    console.log("Transaction Confirmed!");
}
 
// Run the functions
checkBalance();  // Check balance
sendTransaction();
`;

export const JSONRPC = `

const axios = require('axios');
 
// Define the Ethereum node URL (Infura, Alchemy, or your local node)
const ethNodeUrl = 'https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID';
 
// JSON-RPC request structure
const jsonRpcRequest = {
    jsonrpc: "2.0",
    method: "eth_blockNumber",  // Example: fetching the latest block number
    params: [],
    id: 1
};
 
// Function to send JSON-RPC request
async function sendJsonRpcRequest() {
    try {
        const response = await axios.post(ethNodeUrl, jsonRpcRequest);
        console.log('Response:', response.data);
    } catch (error) {
        console.error('Error making JSON-RPC request:', error);
    }
}
 
// Send the JSON-RPC request
sendJsonRpcRequest();
`;

export const SWAP = `

const { ethers } = require('ethers');
 
// Define the network and the Uniswap V2 Router contract address
const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID');
const wallet = new ethers.Wallet('YOUR_PRIVATE_KEY', provider);
const uniswapRouterAddress = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';  // Uniswap V2 Router contract
 
// Define the Router ABI for swapExactETHForTokens function
const uniswapAbi = [
    "function swapExactETHForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)"
];
 
// Create an instance of the Uniswap Router contract
const uniswapContract = new ethers.Contract(uniswapRouterAddress, uniswapAbi, wallet);
 
// Token addresses (ETH is implicitly used in Uniswap)
// Replace with the token you want to swap to, e.g., DAI (0x6B175474E89094C44Da98b954EedeAC495271d0F for DAI)
const tokenAddress = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
 
// Swap ETH for tokens
async function swapETHForTokens() {
    const amountOutMin = ethers.utils.parseUnits('1', 18);  // Minimum amount of tokens you want (e.g., 1 DAI)
    const amountIn = ethers.utils.parseEther('0.1');  // Amount of ETH to swap (e.g., 0.1 ETH)
 
    const path = [
        ethers.constants.AddressZero,  // ETH
        tokenAddress                    // Token (e.g., DAI)
    ];
 
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20;  // 20 minutes from the current Unix time
 
    const tx = await uniswapContract.swapExactETHForTokens(
        amountOutMin,
        path,
        wallet.address,
        deadline,
        { value: amountIn }
    );
 
    console.log("Transaction Hash:", tx.hash);
 
    // Wait for the transaction to be confirmed
    const receipt = await tx.wait();
    console.log("Swap confirmed in block:", receipt.blockNumber);
}
 
// Execute the swap
swapETHForTokens().catch(console.error);
`;

export const CrossBridge = `

const { ethers } = require('ethers');
 
// Ethereum and BSC Providers
const ethProvider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID');
const bscProvider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
 
 // Ethereum wallet and contract interaction
const ethWallet = new ethers.Wallet('YOUR_ETHEREUM_PRIVATE_KEY', ethProvider);
 
// Bridge contract ABI
const bridgeAbi = [
    "function lockTokens(address token, uint amount) external",
    "event Locked(address indexed sender, uint amount, address token, string targetChain)"
];
 
// Ethereum Bridge Contract Address
const ethBridgeContract = new ethers.Contract('ETHEREUM_BRIDGE_CONTRACT_ADDRESS', bridgeAbi, ethWallet);
 
async function lockTokensOnEthereum(tokenAddress, amount) {
    const tx = await ethBridgeContract.lockTokens(tokenAddress, amount);
    console.log("Transaction Hash:", tx.hash);
 
    // Wait for confirmation
    const receipt = await tx.wait();
    console.log("Transaction Confirmed in Block:", receipt.blockNumber);
}
`;

export const AIML = `

# Import necessary libraries
from sklearn.datasets import load_iris
from sklearn.model_selection import train_test_split
from sklearn.tree import DecisionTreeClassifier
from sklearn.metrics import accuracy_score
 
 # Load the Iris dataset
iris = load_iris()
X = iris.data  # Features
y = iris.target  # Labels
 
# Split the data into training and testing sets
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.3, random_state=42)
 
# Initialize the Decision Tree Classifier
clf = DecisionTreeClassifier()
 
# Train the model
clf.fit(X_train, y_train)
 
# Make predictions on the test set
y_pred = clf.predict(X_test)
 
# Evaluate the accuracy
accuracy = accuracy_score(y_test, y_pred)
print(f"Model Accuracy: {accuracy * 100:.2f}%")
`;

export const DEX = `

const { ethers } = require('ethers');
 
// Connect to an Ethereum node via Infura (replace with your Infura project ID)
const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID');
 
const wallet = new ethers.Wallet('YOUR_PRIVATE_KEY', provider); // Load wallet (replace with your private key and make sure it has enough ETH)
 

// Uniswap V2 Router contract address
const uniswapRouterAddress = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';  // Uniswap V2 Router
 
// Uniswap V2 Router ABI (simplified)
const uniswapRouterAbi = [
  'function swapExactETHForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)'
];
 
// Create an instance of the Uniswap Router contract
const uniswapContract = new ethers.Contract(uniswapRouterAddress, uniswapRouterAbi, wallet);
 
// Token address for DAI (example token)
const daiAddress = '0x6B175474E89094C44Da98b954EedeAC495271d0F';
 
// Swap ETH for DAI
async function swapETHForDAI() {
  const amountIn = ethers.utils.parseEther('0.1');  // Swap 0.1 ETH
  const amountOutMin = ethers.utils.parseUnits('1', 18);  // Minimum of 1 DAI expected in return
 
  // Define the swap path (ETH -> DAI)
  const path = [
    ethers.constants.AddressZero,  // WETH (ETH)
    daiAddress                     // DAI token address
  ];
 
  // Set a deadline (current time + 20 minutes)
  const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
 
  // Execute the swap transaction
  const tx = await uniswapContract.swapExactETHForTokens(
    amountOutMin,
    path,
    wallet.address,
    deadline,
    { value: amountIn }  // The amount of ETH to swap
  );
 
  console.log('Transaction Hash:', tx.hash);
 
  // Wait for the transaction to be confirmed
  const receipt = await tx.wait();
  console.log('Transaction Confirmed:', receipt.blockNumber);
}
 
// Call the swap function
swapETHForDAI().catch(console.error);
`;

export const SPHINICS = `

from pqcrypto.sign.sphincsplus
import generate_keypair, sign, verify
 
# Generate a keypair
public_key, secret_key = generate_keypair()
 
 message = b'This is a message for SPHINCS+ signing.'  # Message to be signed
 
# Sign the message with the secret key
signature = sign(message, secret_key)
 
print(f"Signature: {signature.hex()}")
 
# Verify the signature with the public key
try:
    verify(message, signature, public_key)
    print("Signature is valid.")
except Exception as e:
    print(f"Signature verification failed: {e}")
`;

export const SHA256 = `

import hashlib
 
# Message to be hashed
message = "Hello, this is a message for SHA-256 hashing."
 

 message_bytes = message.encode('utf-8')  # Encode the message to bytes
 
# Create a SHA-256 hash object
sha256_hash = hashlib.sha256()
 
# Update the hash object with the message bytes
sha256_hash.update(message_bytes)
 
# Retrieve the hexadecimal digest of the hash
hash_digest = sha256_hash.hexdigest()
 
print(f"SHA-256 Hash: {hash_digest}")
`;
